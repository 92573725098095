import WorkTypes from "./work.types";

const INITIAL_STATE = {
  title: "SELECTED WORK",
  description: "Selection by Irina Munteanu",
  albums: null,
  isFetching: true,
  error: null
};
const compare = (a, b) => {
  if (a?.order < b?.order) {
    return -1;
  }
  if (a?.order > b?.order) {
    return 1;
  }
  return 0;
}
const workReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case WorkTypes.FETCH_WORK_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case WorkTypes.FETCH_WORK_SUCCESS:
      payload.forEach(album => {
        album.images = album.images.sort(compare)
      })
      return {
        ...state,
        albums: payload,
        error: null,
        isFetching: false
      };
    case WorkTypes.FETCH_WORK_FAILED:
      return {
        ...state,
        albums: null,
        error: payload,
        isFetching: false
      };
    default:
      return state;
  }
};

export default workReducer;
