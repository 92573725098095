import { takeLatest, call, put, all } from "redux-saga/effects";
import ProjectsTypes from "./projects.types";
import { fetchProjectsFailure, fetchProjectsSuccess } from "./projects.actions";
import { getCollectionFromFirebase } from "../../firebase";

export function* fetchProjectsAsync() {
  try {
    const albums = yield getCollectionFromFirebase("projects");
    yield put(fetchProjectsSuccess(albums));
  } catch (error) {
    yield put(fetchProjectsFailure(error));
  }
}

export function* fetchProjectsStart() {
  yield takeLatest(ProjectsTypes.FETCH_PROJECTS_START, fetchProjectsAsync);
}

export function* projectsSagas() {
  yield all([call(fetchProjectsStart)]);
}
