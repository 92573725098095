import WorkTypes from './work.types';

export const fetchWorkStart = () => ({
  type: WorkTypes.FETCH_WORK_START,
});

export const fetchWorkSuccess = albums => ({
  type: WorkTypes.FETCH_WORK_SUCCESS,
  payload: albums,
});

export const fetchWorkFailure = error => ({
  type: WorkTypes.FETCH_WORK_FAILED,
  payload: error,
});