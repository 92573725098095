import NewsTypes from './news.types';

const INITIAL_STATE = {
  media: null,
  error: null,
  isFetching: true,
};

const newsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case NewsTypes.FETCH_NEWS_START:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case NewsTypes.FETCH_NEWS_SUCCESS:
      return {
        ...state,
        media: payload,
        error: null,
        isFetching: false,
      };
    case NewsTypes.FETCH_NEWS_FAILED:
      return {
        ...state,
        media: null,
        error: payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default newsReducer;