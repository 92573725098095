import { combineReducers } from "redux";
import workReducer from "./work/work.reducer";
import projectsReducer from "./projects/projects.reducer";
import uiReducer from "./ui/ui.reducer";
import newsReducer from "./news/news.reducer";

const rootReducer = combineReducers({
  work: workReducer,
  projects: projectsReducer,
  ui: uiReducer,
  news: newsReducer
});

export default rootReducer;
