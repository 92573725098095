import NewsTypes from './news.types';

export const fetchNewsStart = () => ({
  type: NewsTypes.FETCH_NEWS_START,
});

export const fetchNewsSuccess = news => ({
  type: NewsTypes.FETCH_NEWS_SUCCESS,
  payload: news,
});

export const fetchNewsFailure = error => ({
  type: NewsTypes.FETCH_NEWS_FAILED,
  payload: error,
});