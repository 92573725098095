import { UiTypes } from "./ui.types";

const INITIAL_STATE = {
  showNavigation: true,
  workLoaded: false
};

const uiReducer = (state = INITIAL_STATE, { type, _ }) => {
  switch (type) {
    case UiTypes.SHOW_NAVIGATION:
      return {
        ...state,
        showNavigation: true
      };
    case UiTypes.HIDE_NAVIGATION:
      return {
        ...state,
        showNavigation: false
      };
    case UiTypes.SET_WORK_LOADED_FALSE:
      return {
        ...state,
        workLoaded: false
      };
    case UiTypes.SET_WORK_LOADED_TRUE:
      return {
        ...state,
        workLoaded: true
      };
    default:
      return state;
  }
};

export default uiReducer;
