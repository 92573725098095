import { getCollectionFromFirebase } from "../../firebase";
import { fetchNewsFailure, fetchNewsSuccess } from "./news.actions";
import { takeLatest, call, put, all } from "redux-saga/effects";
import NewsTypes from "./news.types";

export function* fetchNewsAsync() {
  try {
    const media = yield getCollectionFromFirebase("news");
    yield put(fetchNewsSuccess(media));
  } catch (error) {
    yield put(fetchNewsFailure(error));
  }
}

export function* fetchNewsStart() {
  yield takeLatest(NewsTypes.FETCH_NEWS_START, fetchNewsAsync);
}

export function* newsSagas() {
  yield all([call(fetchNewsStart)]);
}
