import ProjectsTypes from "./projects.types";

const INITIAL_STATE = {
  title: "PROJECTS",
  description: "",
  albums: null,
  isFetching: true,
  error: null
};

const projectsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ProjectsTypes.FETCH_PROJECTS_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case ProjectsTypes.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        albums: payload,
        error: null,
        isFetching: false
      };
    case ProjectsTypes.FETCH_PROJECTS_FAILED:
      return {
        ...state,
        albums: null,
        error: payload,
        isFetching: false
      };
    default:
      return state;
  }
};

export default projectsReducer;
