import ProjectsTypes from './projects.types';

export const fetchProjectsStart = () => ({
  type: ProjectsTypes.FETCH_PROJECTS_START,
});

export const fetchProjectsSuccess = albums => ({
  type: ProjectsTypes.FETCH_PROJECTS_SUCCESS,
  payload: albums,
});

export const fetchProjectsFailure = error => ({
  type: ProjectsTypes.FETCH_PROJECTS_FAILED,
  payload: error,
});