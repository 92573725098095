import React, { Fragment, lazy, Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { fetchProjectsStart } from "./state/projects/projects.actions";
import { fetchWorkStart } from "./state/work/work.actions";
import { setWorkLoadedFalse } from "./state/ui/ui.actions";
import { fetchNewsStart } from "./state/news/news.actions";

const SelectedWork = lazy(() => import("./containers/selected-work"));
const Projects = lazy(() => import("./containers/projects"));
const SelectedWorkAlbum = lazy(() =>
  import("./containers/selected-work-album")
);
const ProjectsAlbum = lazy(() => import("./containers/projects-album"));
const SelectedWorkImage = lazy(() =>
  import("./containers/selected-work-image")
);
const ProjectsImage = lazy(() => import("./containers/projects-image"));
const Home = lazy(() => import("./components/home"));
const Navigation = lazy(() => import("./components/navigation"));
const NewsContainer = lazy(() => import("./containers/news-container"));

const App = ({
  fetchProjectsStart,
  fetchWorkStart,
  fetchNewsStart,
}) => {
  useEffect(() => {
    fetchProjectsStart();
    fetchWorkStart();
    fetchNewsStart();
  }, [fetchProjectsStart, fetchWorkStart, fetchNewsStart]);

  return (
    <Fragment>
      <Suspense fallback={<div />}>
        <Navigation />
        <Switch>
          <Route exact path={"/"} component={Home} />
          <Route exact path={"/work"} component={SelectedWork} />
          <Route exact path={"/work/:id"} component={SelectedWorkAlbum} />
          <Route path={"/work/:album/:id"} component={SelectedWorkImage} />
          <Route exact path={"/projects"} component={Projects} />
          <Route exact path={"/projects/:id"} component={ProjectsAlbum} />
          <Route path={"/projects/:album/:id"} component={ProjectsImage} />
          <Route path={"/news/:type"} component={NewsContainer} />
          <Route
            path="*"
            render={() => (
              <h1 style={{ marginLeft: "70px" }}>This does not exist.</h1>
            )}
          />
        </Switch>
      </Suspense>
    </Fragment>
  );
};
const mapDispatchToProps = dispatch => ({
  fetchProjectsStart: () => dispatch(fetchProjectsStart()),
  fetchWorkStart: () => dispatch(fetchWorkStart()),
  fetchNewsStart: () => dispatch(fetchNewsStart()),
  setWorkLoaderFalse: () => dispatch(setWorkLoadedFalse())
});
export default connect(null, mapDispatchToProps)(App);
