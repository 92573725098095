import { takeLatest, call, put, all } from "redux-saga/effects";
import { getCollectionFromFirebase } from "../../firebase";
import { fetchWorkFailure, fetchWorkSuccess } from "./work.actions";
import WorkTypes from "./work.types";

export function* fetchWorkAsync() {
  try {
    const albums = yield getCollectionFromFirebase("selected-work");
    yield put(fetchWorkSuccess(albums));
  } catch (error) {
    yield put(fetchWorkFailure(error));
  }
}

export function* fetchProjectStart() {
  yield takeLatest(WorkTypes.FETCH_WORK_START, fetchWorkAsync);
}

export function* workSagas() {
  yield all([call(fetchProjectStart)]);
}
