import { UiTypes } from './ui.types';

export const showNavigation = () => ({
  type: UiTypes.SHOW_NAVIGATION,
});

export const hideNavigation = () => ({
  type: UiTypes.HIDE_NAVIGATION,
});

export const setWorkLoadedFalse = () => ({
  type: UiTypes.SET_WORK_LOADED_FALSE,
});

export const setWorkLoadedTrue = () => ({
  type: UiTypes.SET_WORK_LOADED_TRUE,
});