import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBZ_OSHU29RMx_ZslitmTpmC8xEEL2mzXQ',
  authDomain: 'react-tutorial01.firebaseapp.com',
  databaseURL: 'https://react-tutorial01.firebaseio.com',
  projectId: 'react-tutorial01',
  storageBucket: 'react-tutorial01.appspot.com',
  messagingSenderId: '719401123103',
  appId: '1:719401123103:web:7d44f2f419c55820e8442a',
};

firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();
export const storage = firebase.storage();

export const getCollectionFromFirebase = async (type) => {
  const snapshot = await firestore.collection(type).get();
  return snapshot.docs.map(doc => doc.data());
};

export default firebase;
